import React, { useContext, useRef } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Button, Text } from "../../Core";
import { navigate } from "gatsby";
import GlobalContext from "./../../../context/GlobalContext";
import CheckAvailability from "../../../components/Rebrand/CheckAvailability/CheckAvailability";
import styled from "styled-components";

const HeroLandlord = (props) => {

    const scrollToRef = (ref) => {
        if (ref && ref.current)
            ref.current.scrollIntoView({
                behavior: "smooth",
            })
    }

    const gContext = useContext(GlobalContext);
    const checkAvailability = useRef(null)

    const PageTitle = styled.h1`
        font-size: 9.5rem;
        line-height: 8rem;
        padding-bottom: 3%;
        color: #fff !important;
        text-align: center;
        span {
            font-size: 9.5rem;
            line-height: 8rem;
            padding-bottom: 3%;
            font-family: 'Abolition-Regular';
            color: #08DE9E !important;
        }
        @media (max-width: 1280px) {
            font-size: 6rem;
            line-height: 5.1rem;
            span {
                font-size: 6rem !important;
                line-height: 5.1rem !important;
            }
        }
    `;

    const HeroSection = styled.div`
        background-color: #08131e;
        padding: 80px 0 100px 0;
    `;

    return (
        <>
            <HeroSection>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <PageTitle className="dark-color">POWER UP YOUR PORTFOLIO<br></br><span className="green-color">partner with us</span></PageTitle>
                            <Row>
                                <Col sm={12} md={6} className="m-auto">
                                    <Text className="text-center white-color">
                                        Landlord looking after lots of lets and baffling broadband contracts?  We could be just the partner you're looking for.
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </HeroSection>
        </>
    )
};

export default HeroLandlord;
