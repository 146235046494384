import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "./../components/Seo";
import { Section, Button, Text } from "./../components/Core";
import CheckAvailability from "../components/Rebrand/CheckAvailability/CheckAvailability";
import HeroLandlord from "../components/Rebrand/Hero/HeroLandlord";
import landlordBroadband from "./../assets/image/jpeg/landlord-broadband.jpg";
import landlordAdam from "./../assets/image/jpeg/landlord-adam.jpg";
import DynamicPricingSection from "../sections/PricingSection/DynamicPricingSection";

import icon1 from "./../assets/image/svg/landlord-guaranteed-speeds.svg";
import icon2 from "./../assets/image/svg/landlord-no-surprise-rises.svg";
import icon3 from "./../assets/image/svg/landlord-your-needs-manager.svg";
import icon4 from "./../assets/image/svg/landlord-resell-for-revenue.svg";
import icon5 from "./../assets/image/svg/landlord-move-connections.svg";
import icon6 from "./../assets/image/svg/landlord-well-fix-it.svg";

import styled from 'styled-components';

const Landlord = () => {

  const Divider = styled.div`
    border: 1px solid #07131e;
  `;

  return (
    <div className="landlord-page">
      <Seo page="landlord" />
      <HeroLandlord />
      <Section>
        <Container>
          <Row className="mb-30">
            <Col xs={12} sm={12} lg={7}>
              <h2>
                BESPOKE BROADBAND<br></br>
                FOR RENTALS
              </h2>
              <Row>
                <Col sm={12} lg={10}>
                  <Text>
                    Get all your lettings online with our ultrafast and affordable service without the juggle.
                    Our team are experts at bringing banging broadband to HMOs, student lets,
                    holiday homes, and flats. You can expect fixed prices on a range of contract lengths;
                    you’ll have account manager support and a 24-hour SLA. Hell, you can even resell our services for more revenue.
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} lg={5}>
              <img src={landlordBroadband} className="w-100" />
            </Col>
          </Row>
        </Container>
      </Section>


      <Section className="green-bg">
        <Container>
          <Row>
            <Col sm={12} className="mb-50">
              <h2 className="text-center pb-0">
                Landlords, Property Management Companies & Student Lets, <span className="white-color">We’ve got you.</span>
              </h2>
              <Row>
                <Col sm={12} lg={8} className="m-auto">
                  <Text className="text-center">
                    If you're a landlord, letting agent, property manager, or you look after long-term,
                    short-term, or student lets, you’ll be in good hands.
                    Our team are dedicated to bringing our full fibre broadband straight
                    into the sort of properties that need something more bespoke.
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>

            <Col sm={12} md={4} className="text-center mb-80">
              <img src={icon1} />
              <Row>
                <Col sm={12} lg={9} className="m-auto">
                  <Text className="largest-font"><strong>Guaranteed Speeds</strong></Text>
                  <Text>From 150 Mbps up to 8000 Mbps upload and download.</Text>
                </Col>
              </Row>
            </Col>

            <Col sm={12} md={4} className="text-center mb-80">
              <img src={icon2} />
              <Row>
                <Col sm={12} lg={9} className="m-auto">
                  <Text className="largest-font"><strong>No surprise rises</strong></Text>
                  <Text>Fixed prices on contract lengths from 24 to 60 months.</Text>
                </Col>
              </Row>
            </Col>

            <Col sm={12} md={4} className="text-center mb-80">
              <img src={icon3} />
              <Row>
                <Col sm={12} lg={9} className="m-auto">
                  <Text className="largest-font"><strong>Your needs managed</strong></Text>
                  <Text>Support from an Account Manager to scope what you need and get it up and running.</Text>
                </Col>
              </Row>
            </Col>

            <Col sm={12} md={4} className="text-center mb-80">
              <img src={icon4} />
              <Row>
                <Col sm={12} lg={9} className="m-auto">
                  <Text className="largest-font"><strong>Resell for revenue</strong></Text>
                  <Text>Bring in more money for your business when you resell our services.</Text>
                </Col>
              </Row>
            </Col>

            <Col sm={12} md={4} className="text-center mb-80">
              <img src={icon5} />
              <Row>
                <Col sm={12} lg={9} className="m-auto">
                  <Text className="largest-font"><strong>Move connections for free</strong></Text>
                  <Text>Switch services between your properties at no cost.</Text>
                </Col>
              </Row>
            </Col>

            <Col sm={12} md={4} className="text-center mb-80">
              <img src={icon6} />
              <Row>
                <Col sm={12} lg={9} className="m-auto">
                  <Text className="largest-font"><strong>We’ll fix it, fast</strong></Text>
                  <Text>A speedy 24-hour SLA for fast fixes if you need them.</Text>
                </Col>
              </Row>
            </Col>

          </Row>
        </Container>
      </Section>

      <Section>
        <h2 className="text-center">Tailored to you</h2>
        <DynamicPricingSection
          location={'landlord'}
          searchParams={''}
        />
      </Section>

      <Section className="green-bg">
        <Container>
          <Row className="mb-30">
            <Col sm={12} lg={5}>
              <img src={landlordAdam} className="w-100" />
            </Col>
            <Col sm={12} lg={6} className="offset-lg-1">
              <Row>
                <Col sm={12} lg={9}>
                  <h2>
                    TALK TO ADAM
                  </h2>

                  <Text>
                    He's our National Account Manager,
                    he’ll find the best solution for you and your portfolio and look after you and your properties.
                    <br></br>
                    <br></br>
                    Email: <a href="mailto:adam.spratt@youfibre.com">adam.spratt@youfibre.com</a>
                    <br></br>
                    Phone: 0800 0541943
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Section >
    </div >
  );
};
export default Landlord;

